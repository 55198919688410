(function () {
    var $form = $('#enquiry-form');

    $('#children', $form).on('input', function () {
        var selectedValue = $(this).val();

        for (var i = 1; i < 11; i++) {
            var element = $('#children-age-' + i);
            if (selectedValue >= i) {
                element.show();
                element.children('input').prop('disabled', false);
            } else {
                element.hide();
                element.children('input').prop('disabled', true);
            }
        }
    });

    $('.form-control', $form).focus(function() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'EnquiryInteraction'
        });

        $('.form-control', $form).off('focus');
    });

    $form.validate({});

    $form.submit(function (e) {
        e.preventDefault();

        var $validator = $form.validate();
        if (!$form.valid()) {
            $form.addClass("form-error");
            return;
        }
        $form.removeClass(".server-error .alert");
        $form.find(".server-error .alert").text("").slideUp();

        loading(true);

        var formData = $form.serializeObject();
        //console.log(formData);
        $.ajax({
            cache: false,
            url: $form.attr('action'),
            type: $form.attr('method') || "POST",
            data: formData,
            success: function (data) {

                console.log(data);

                if (data != undefined && data.Success === true) {
                    var $thankYou = $form.siblings("#thank-you")

                    $form.fadeOut(300,
                        function () {
                            $thankYou.fadeIn(300);
                        });

                    setTimeout(function () {
                        $validator.resetForm();
                    }, 400);

                    $("#btn-thank-you", $thankYou).click(function () {
                        $thankYou.fadeOut(300,
                            function () {
                                $form.fadeIn(300);
                            });
                    });

                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'EnquirySuccess'
                    });

                } else {
                    if (data && data.Errors) {
                        $validator.saveShowErrors(data.Errors);
                    } else {
                        $form.find(".server-error .alert").text((data || {}).msg || $form.data("fatal-msg"))
                            .slideDown();
//                        ga('send', 'event', 'formular', 'anfrage', 'fehler');
                    }
                    console.log($form[0].id + " error:", data);
                }
                loading(false);
            },
            error: function (data) {
                $form.find(".server-error .alert").text($form.data("fatal-msg")).slideDown();

                console.log($form[0].id + " error fatal:", data);
                loading(false);
            }
        });

    });

    function loading(isLoading) {
        $form.find('.load-spinner').toggleClass('spin', isLoading);
        $form.find(':submit').prop('disabled', isLoading);
    }
})();


(function () {
    "use strict";

    // serializeObject
    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a,
            function () {
                if (o[this.name]) {
                    if (!o[this.name].push) {
                        o[this.name] = [o[this.name]];
                    }
                    o[this.name].push(this.value || "");
                } else {
                    o[this.name] = this.value || "";
                }
            });
        return o;
    };

    // validator saveShowErrors extension
    $.validator.prototype.saveShowErrors = function (errors) {
        var fixedErrors = {};
        for (var e in errors) {
            if (errors.hasOwnProperty(e)) {
                var fixedName = e.replace(/^enquiryData\./, '').toLowerCase();
                fixedErrors[fixedName] = errors[e];
            }
        }

        errors = fixedErrors;

        var deletedErrors = "";
        for (var e in errors) {
            if (errors.hasOwnProperty(e)) {
                var el = $("[name='" + e + "']")[0];
                if (!el) {
                    if (e === "regionid") {
                    }
                    deletedErrors += e + " : " + errors[e] + "\n";
                    delete errors[e];
                }
            }
        }
        // TODO better error messageing;
        if (deletedErrors !== "") {
            deletedErrors = "$.validator: could not display all errors:\n" + deletedErrors;
            console.error(deletedErrors);
            alert(deletedErrors);
        }
        console.log(errors);
        this.showErrors(errors);
    };
})();