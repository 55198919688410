/*
There is an issue when the slick slides are placed in an hidden element
https://github.com/kenwheeler/slick/issues/187
*/
$('.accordion-prices-body').on('shown.bs.collapse', function (e) {
    $('.carousel-prices', this).each(function (index, item) {
        item.slick.setPosition();
        console.log('setPosition');
    });
});
