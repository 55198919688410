$(function ($) {

    $('[data-gaq]').each(function () {
        var $element = $(this);

        var data = $element.data('gaq').split("|");
        var cat = data[0];
        var act = data[1];
        var lab = data[2];

        $element.click(function () {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'CustomClick',
                'eventCategory': cat,
                'eventAction': act,
                'eventLabel': lab                
            });
        });
    });

});
